.footer {
    text-align: center;
    width: $content-width;
    border-top: 1px solid $teal-1;
    margin: 40px auto 0;
    padding-top: 20px;
    margin-top: auto;
    align-self: flex-end;

    a {
        color: lighten($teal-1, 5);
        font-size: 16px;
        padding: 0 5px;
    }


    @media screen and (max-width: $content-width) {
        width: 100%;
    }
}