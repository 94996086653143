.services {
  .services-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .services-list {
      padding: 40px 0;
      li {
        margin-bottom: 10px;
        line-height: 40px;
        display: flex;
        h2 {
          margin: 0;
        }

        &::before {
          content: " ";
          height: 12px;
          min-width: 12px;
          border: 1px solid $teal-2;
          border-radius: 50%;
          margin-right: 20px;
          margin-top: 15px;
        }
      }

      h2 {
        font-size: 18px;
        margin-bottom: 15px;
        i {
          color: $teal-2;
          margin-right: 10px;
        }
      }
    }

    .services-images {
      width: 400px;
      background-image: url("../images/background.png");
      background-position: center;
      text-align: center;
      padding-top: 20px;

      img {
        max-width: 230px;
        margin: 0px 20px 20px 140px;
      }
    }

    @media screen and (max-width: $content-width) {
      align-items: flex-start;
      .services-list {
        padding: 0px 0;
      }

      .services-images {
        background: none;
        width: auto;
        img {
          margin-left: 20px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      align-items: center;
    }
  }
}
