@import url("https://use.typekit.net/utu4trt.css");
@import "variables";
@import "fonts";

@import "./navigation";
@import "./footer";
@import "./home";
@import "./services";
@import "./aboutme";
@import "./appointments";

body {
  // @include vectora-roman;
  @include humnst;
  color: $black;

}

h1 {
  // @include p22-zaner;
  @include vladimir;
}

h2,
h3,
.highlighted-text {
  @include meno-banner;
  @include humnst;
}

h3,
.highlighted-text {
  font-weight: 600;
  color: $teal-4;
  margin-bottom: 10px;
  margin-top: 24px;
}

.highlighted-text {
  margin-bottom: 24px;
}

.mt {
  margin-top: 8px;
}

p,
b {
  line-height: 140%
}

a {
  @include meno-banner;
  @include humnst;
  text-decoration: none;
  color: $teal-1;
  color: lighten($teal-1, 5);
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    color: $teal-1;
    text-decoration: underline;
  }
}

a.button-link {
  color: #fff;
  background-color: $teal-1;
  padding: 4px 12px;
  margin: 10px 10px;
  display: block;
  width: fit-content;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: lighten($teal-1, 10);
    text-decoration: none;
  }
}

ul {
  margin-bottom: 24px;
}

li {
  margin-bottom: 10px;
}

.main {
  margin: 0 auto;
  padding: 20px 0;
  min-height: calc(100vh - 40px);

  display: flex;
  flex-direction: column;
}

.banner {
  background-image: url("../images/banner4.jpeg");
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-position: 100% 6%;
  height: 100px;
  margin-bottom: 30px;
  padding: 0 $content-padding;
  display: flex;
  align-items: center;

  h1 {
    color: #fff;
    font-size: 50px;
    margin: 0;
    width: $content-width;
    text-shadow: 1px 2px 2px $teal-4;
  }

  @media screen and (max-width: $content-width) {
    margin-top: 0px;
    padding: 0 $content-padding-md;
    margin-bottom: 20px;
    background-position: 100% 80%;
  }


  @media screen and (max-width: 768px) {
    background-size: 120vw auto;
    height: 70px;

    h1 {
      text-align: center;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0 $content-padding-mobile;
    background-size: 200vw auto;
    background-position: 25% 40%;
    height: 100px;

    h1 {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 370px) {
    padding: 0 $content-padding-mobile;
    background-size: 300vw auto;
    background-position: 25% 40%;
    height: 140px;

    h1 {
      font-size: 40px;
    }
  }
}

.about-me-wrapper,
.services-wrapper,
.home-wrapper,
.data-privacy-wrapper,
.imprint-wrapper {
  max-width: $content-width;
  margin: 0 auto;
}

@media screen and (max-width: $content-width) {

  .home-wrapper,
  .about-me-wrapper,
  .services-wrapper,
  .data-privacy-wrapper,
  .imprint-wrapper {
    max-width: 100vw;
    padding: 0 $content-padding-md;
  }
}

@media screen and (max-width: 768px) {

  .about-me-wrapper,
  .services-wrapper,
  .data-privacy-wrapper,
  .imprint-wrapper,
  .navigation {
    flex-direction: column;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 576px) {

  .home-wrapper,
  .about-me-wrapper,
  .services-wrapper,
  .data-privacy-wrapper,
  .imprint-wrapper,
  .navigation {
    padding: 0 $content-padding-mobile;

  }
}


.fade-in {
  opacity: 0;
  animation: fade-in 1.5s ease 0.3s forwards;
}

.fade-in-1 {
  opacity: 0;
  animation: fade-in 1.5s ease 0.5s forwards;
}

.fade-in-2 {
  opacity: 0;
  animation: fade-in 1.5s ease 1s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.roll-in {
  opacity: 0;
  animation: roll-in 1.5s ease forwards;
}

@keyframes roll-in {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}