header {
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-img {
      margin-right: 20px;

      img {
        height: 75px;
      }

      transition: all 0.3s ease;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
    }

    h2 {
      color: $teal-2;
      font-size: 30px;
    }

    h3 {
      // @include p22-zaner;
      @include vladimir;
      color: $teal-3;
      font-size: 32px;
    }

    h2,
    h3 {
      margin: 0;
      text-align: center;
    }

    .mobile-menu-trigger {
      display: none;
    }
  }
}

.navigation {
  text-align: center;
  margin: 20px 0;

  a {
    color: $black;
    padding: 0px 40px;
    font-size: 22px;
    font-weight: 400;
    
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: lighten($teal-1, 5);

      span {
        padding-bottom: 2px;
        border-bottom: 2px solid $teal-1;
        transition: all 0.3s ease-in;
      }
    }


    &.selected {
      color: $teal-1;

      span {
        padding-bottom: 2px;
        border-bottom: 2px solid $teal-1;
        transition: all 0.3s ease-in;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    .navigation {
      margin-bottom: 0;
      transition: all 0.5s ease;
      max-height: 0;
      overflow-y: hidden;

      &.open {
        height: auto; 
        max-height: 350px;
        margin-bottom: 20px;
      }

      a {
        display: block;
        padding-bottom: 10px;
        margin: 10px 0 5px 0;
        border-bottom: 1px solid $teal-4;

        &.selected {
          border-color: $teal-1;

          span {
            border-bottom: none;
          }
        }
      }
    }

    .logo {
      transition: all 0.5s ease-out;
      padding: 0 $content-padding-md;
      justify-content: space-between;

      .logo-img {
        margin-right: 0px;

        img {
          height: auto;
          width: 50px;
        }
      }

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 26px;
      }

      .mobile-menu-trigger {
        display: block;
        background: transparent;
        border: none;
        padding: 0;
        font-size: 30px;
        width: 50px;
        color: $teal-4;

        &:focus,
        &:active,
        &:hover {
          // outline: none;
          color: $teal-1;
        }
      }
    }
  }
}


@media screen and (max-width: 576px) {

  header .logo {
    padding: 0 $content-padding-mobile;
  }
}