@font-face {
  font-family: 'Humnst777 Lt BT Light';
  src: URL('./fonts/Humnst777\ Lt\ BT\ Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Vladimir Script';
  src: URL('./fonts/VLADIMIR.TTF') format('truetype');
}

@mixin humnst {
  font-family: 'Humnst777 Lt BT Light', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin vladimir {
  font-family: 'Vladimir Script', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin p22-zaner {
  font-family: p22-zaner-pro-four, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin meno-banner-light {
  font-family: meno-banner, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin meno-banner {
  font-family: meno-banner, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin meno-banner-italic {
  font-family: meno-banner, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin meno-banner-bold {
  font-family: meno-banner, serif;
  font-weight: 700;
  font-style: normal;
}

@mixin meno-banner-bold-italic {
  font-family: meno-banner, serif;
  font-weight: 700;
  font-style: italic;
}

@mixin vectora-light {
  font-family: linotype-vectora, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin vectora-light-italic {
  font-family: linotype-vectora, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin vectora-roman {
  font-family: linotype-vectora, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin vectora-bold {
  font-family: linotype-vectora, sans-serif;
  font-weight: 700;
  font-style: normal;
}
