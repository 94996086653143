.home-wrapper {

  margin-bottom: 20px;

  display: grid;

  grid-template-columns: 580px auto;
  $col-1: 2;
  $col-2: 1;

  .portrait-wrapper {
    grid-column-start: $col-1;
    grid-column-end: $col-1;
    grid-row-start: 1;
    grid-row-end: span 2;

    align-self: center;

    width: 400px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .home-header {
    grid-column-start: $col-2;
    grid-column-end: $col-2;
    grid-row-start: 1;
    grid-row-end: 1;
    font-size: 20px;

    h2 {
      @include humnst;
      margin: 0px 0 10px;
    }

    .supporting-text {
      font-style: italic;
    }
  }

  .home-information {
    width: 100%;
    grid-column-start: $col-2;
    grid-column-end: $col-2;
    grid-row-start: 2;
    grid-row-end: 2;

    h2 {
      color: $teal-4;
      font-size: 26px;
    }
  }

  .wr {
    flex-wrap: wrap;

    >div {
      margin-right: 40px;
    }
  }

  .info-wrapper, h3 {
    i {
      margin-right: 10px;
      color: $teal-1;
    }

    div {
      margin-bottom: 12px;
    }
  }

  li::marker {
    font-size: 0;
  }

  @media screen and (max-width: $content-width) {
    grid-template-columns: auto;

    .portrait-wrapper {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;

      width: 30vw;
      margin-bottom: 20px;
    }

    .home-header {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;

      h1 {
        margin-top: 40px;
        font-size: 30px;
      }
    }

    .home-information {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;
      justify-self: center;
    }
  }


  @media screen and (max-width: 768px) {
    .portrait-wrapper {
      justify-self: center;
    }
  }


  @media screen and (max-width: 576px) {
    .portrait-wrapper {
      width: 50vw;
    }
  }
}